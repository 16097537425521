const $ = jQuery;
var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
if (supportsTouch) {
  jQuery('body').addClass('touch-device');
}
import DataTable from 'datatables.net-dt';
import tippy from 'tippy.js/dist/tippy.esm'
import {Loader} from "@googlemaps/js-api-loader"
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import whatInput from './plugins/what-input'

const countTo = require('./plugins/countTo.js');

$(document).foundation();

let table = new DataTable('#myTable', {
  paging: false,
  ordering: false,
  language: {
    search: "Tabelle durchsuchen"
  }
});


import './plugins';
import './modules/navigation.js'

// const Filter = require('./modules/postsfilter.js');
let timeout;

$(window).on('scroll', function () {
  window.clearTimeout(timeout);
  timeout = window.setTimeout(function () {
    addBodyScrolled();
  }, 50);
})
$('.timeline-slider-wrap').on('init', function () {
  var elem = new Foundation.Equalizer($('[data-equalizer-man]'), {});
});

$('.count-up').countTo({
  speed: 3000,
  refreshInterval: 5
});

$('.single-fachausschuss .section').each(function (i, el) {
  let $el = $(el);
  // index is odd
  let isOdd = i % 2 === 0;
  if (!isOdd && !$el.hasClass('section--two')) {
    $el.addClass('section--two');
  }
  if (isOdd && $el.hasClass('section--two')) {
    $el.removeClass('section--two');
  }
});


tippy('g[id^="ak-"]', {
  arrow: true,
  interactive: true,
  appendTo: () => document.body,
  allowHTML: true,
  maxWidth: '400px',
  trigger: 'mouseenter',
  animation: 'scale',
  inertia: true,
  hideOnClick: true,
  content: function (reference) {
    const id = reference.getAttribute('id');
    let tplId = 'map-tooltip-' + id;
    const template = document.getElementById(tplId);
    if (template) {
      return template.innerHTML;
    }
  },
});

tippy('g[id^="ab-"]', {
  arrow: true,
  interactive: true,
  appendTo: () => document.body,
  allowHTML: true,
  maxWidth: '400px',
  trigger: 'mouseenter',
  animation: 'scale',
  inertia: true,
  hideOnClick: true,
  content: function (reference) {
    const id = reference.getAttribute('id');
    let tplId = 'map-tooltip-' + id;
    const template = document.getElementById(tplId);
    if (template) {
      return template.innerHTML;
    }
  },
});

$('[data-ak-map-target]').on('mouseenter', function () {
  let data = $(this).data('ak-map-target');
  let el = document.querySelector('#' + data);
  if (el._tippy) {
    el._tippy.show();
  }
});

$('[data-ak-map-target]').on('mouseleave', function () {
  let data = $(this).data('ak-map-target');
  let el = document.querySelector('#' + data);
  if (el._tippy) {
    el._tippy.hide();
  }
});

// var $el = $('[data-posts-filter]');
// if ($el.length === 1) {
//   Filter.initialize({
//     el: $el
//   })
// }

let $prev = $(".slide-m-prev");
let $next = $(".slide-m-next");

$prev.on('click', function () {
  $('.news-slider').slick('slickPrev');
});

$next.on('click', function () {
  $('.news-slider').slick('slickNext');
});
$('.quotes-slider').slick({
  arrows: false,
  autoplay: false,
  dots: true,
  fade: false,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  appendDots: $(".slide-m-dots"),
  prevArrow: $prev,
  nextArrow: $next,
  responsiveFirst: true,
  variableHeight: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
});

$('.news-slider').slick({
  arrows: false,
  autoplay: false,
  dots: true,
  fade: false,
  autoplaySpeed: 5000,
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: false,
  appendDots: $(".slide-m-dots"),
  prevArrow: $prev,
  nextArrow: $next,
  responsiveFirst: true,
  variableHeight: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
});

$('.hero--bg-slider').slick({
  autoplay: true,
  fade: true,
  arrows: true,
  responsiveFirst: true,
  variableHeight: true,
  autoplaySpeed: 5000,
  dots: true,
});


$('.timeline-slider-wrap').each(function (i, el) {
  var slides = $(el).data('slides');
  $(el).slick({
    arrows: true,
    autoplay: false,
    fade: false,
    autoplaySpeed: 5000,
    slidesToShow: slides,
    slidesToScroll: 1,
    infinite: false,
    responsiveFirst: true,
    variableHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });
  var firstSlick = $('.slick-active:first', el);
  var lastSlick = $('.slick-slide:last', el);

  $(firstSlick).addClass('first-slick');
  $(lastSlick).addClass('last-slick');
})
let _prevScrollPosition = 0;

function addBodyScrolled() {
  const scrollTop = $('html').scrollTop();
  $('[data-submenu]').removeClass('active');
  $('[data-dropdown]').removeClass('active');

  if (_prevScrollPosition > scrollTop) {
    if (scrollTop > 100) {
      $('body').addClass('scrolled');
      $('.site-header').addClass('scrolled-up');
      $('.site-header').removeClass('scrolled-down');
    } else {
      $('body').removeClass('scrolled');

    }

    if (scrollTop > 90) {
      $('body').addClass('nav-scrolled');
    } else {
      $('body').removeClass('nav-scrolled');
    }


  } else {


    $('.site-header').removeClass('scrolled-up');
    $('.site-header').addClass('scrolled-down');
    if (scrollTop > 90) {
      $('body').removeClass('scrolled');
    } else {
      $('body').addClass('scrolled');
    }
  }


  _prevScrollPosition = scrollTop;

}

$('a[href*="pdf"]').click(function (e) {
  e.preventDefault(); // stop the existing link from firing
  var documentUrl = $(this).attr("href"); // get the url of the pdf
  window.open(documentUrl, '_blank'); // open the pdf in a new window/tab
});

$('.toggle--trigger').on('click', function () {
  $(this).next('.toggle--container').slideToggle(350);
});

$('.flip-card-icon').on('click', function () {
  $(this).closest('.card-body').toggleClass('flipped');
});

async function initMap() {
  const $mapcontainer = $('.map-container');

  const loader = new Loader({
    apiKey: "AIzaSyC5g0s_vmcCpL7EBrZ-g-w08dHefXbynT8",
    version: "weekly",
  });

  loader.load().then(async () => {
    const {Map} = await google.maps.importLibrary("maps");
    const {Marker} = await google.maps.importLibrary("marker");
    const $el = $('#standortmap');
    if ($el.length === 0) {
      return;
    }
    let map = new Map(document.getElementById("standortmap"), {
      center: {lat: $el.data('lat'), lng: $el.data('lng')},
      zoom: 12,
    });

    const marker = new Marker({
      map: map,
      position: {lat: $el.data('lat'), lng: $el.data('lng')},
    });

  });

}

window.initMap = initMap;

$('.header-toggle').on('click', function () {
  $('body').toggleClass('mobile-nav-open');
});

$('[data-menu-toggle]').hoverIntent(function () {
  let target = $(this).data('menu-toggle');
  $(this).parent().toggleClass('active-child-menu');
  $('[data-menu-toggle-container="' + target + '"]').toggleClass('active').slideToggle(350);
});

$('[data-mobile-toggle]').on('click', function () {
  let target = $(this).data('mobile-toggle');
  $(this).toggleClass('active');
  $('[data-mobile-toggle-container="' + target + '"]').toggleClass('active').slideToggle(350);

});

$('[data-dropdown-toggle]').on('click', function (e) {
  $('[data-dropdown-toggle]').not(this).removeClass('touched');
  let inType = whatInput.ask();
  let touched = $(this).hasClass('touched');
  let target = $(this).data('dropdown-toggle');
  let $target = $('[data-dropdown="' + target + '"]');
  if ($target.length === 0) {
    return e;
  }


  if (inType === 'touch' && !touched) {
    $(this).addClass('touched');
    $('[data-dropdown]').removeClass('active');
    // $('[data-initial]').show();
    $('[data-dropdown="' + target + '"]').toggleClass('active');
    e.preventDefault();
  }
});

$('[data-submenu-toggle]').on('click', function (e) {
  let inType = whatInput.ask();
  $('[data-submenu-toggle]').not(this).removeClass('touched');
  let touched = $(this).hasClass('touched');
  $('[data-initial]').hide();

  if (inType === 'touch' && !touched) {
    let target = $(this).data('submenu-toggle');
    $(this).addClass('touched');
    $('[data-submenu]').removeClass('active');
    if (!target || target === '') {
      $('[data-initial]').show();
      return;
    }
    $('[data-submenu="' + target + '"]').toggleClass('active');
    e.preventDefault();
  }
});

$('[data-sub-submenu-toggle]').on('click', function (e) {
  let inType = whatInput.ask();
  $('[data-sub-submenu-toggle]').not(this).removeClass('touched');
  let touched = $(this).hasClass('touched');
  if (inType === 'touch' && !touched) {
    let target = $(this).data('sub-submenu-toggle');
    $(this).addClass('touched');
    $('[data-sub-submenu]').removeClass('active');
    if (!target || target === '') {
      $('[data-initial]').show();
      return;
    }
    $('[data-sub-submenu="' + target + '"]').toggleClass('active');
    $('[data-initial]').hide();
    e.preventDefault();
  }
});

$('a').each(function () {
  let a = new RegExp('/' + window.location.host + '/');
  if (!a.test(this.href)) {
    $(this).attr("target", "_blank");
  }
});

$('[data-dropdown-toggle]').hoverIntent(function (e) {
  let inType = whatInput.ask();
  let target = $(this).data('dropdown-toggle');

  let $firstSub = $('[data-dropdown="' + target + '"]').find('[data-submenu-toggle]').first();
  let subTarget = $firstSub.data('submenu-toggle');


  // $('[data-submenu="' + subTarget + '"]').toggleClass('active first-active');
  $('[data-dropdown]').removeClass('active');
  // $('[data-initial]').show();
  $('[data-dropdown="' + target + '"]').toggleClass('active');

});

$('[data-submenu-toggle]').hoverIntent(function () {
  $('[data-initial]').hide();
  let target = $(this).data('submenu-toggle');
  $('[data-submenu]').removeClass('active');
  $('[data-sub-submenu]').removeClass('active');
  let id = $(this).data('id');
  if (id === 3017 || id === 3016) {
    $('[data-initial]').show();

  }
  if (!target || target === '') {
    return;
  }
  $('[data-submenu="' + target + '"]').toggleClass('active');
});


$('[data-sub-submenu-toggle]').hoverIntent(function () {
  let target = $(this).data('sub-submenu-toggle');
  $('[data-initial]').hide();
  $('[data-sub-submenu]').removeClass('active');
  if (!target || target === '') {
    // $('[data-initial]').show();
    return;
  }
  $('[data-sub-submenu="' + target + '"]').toggleClass('active');
});

// $('.nav-dropdown').hoverIntent({
//   over: function () {},
//   out: function () {
//     if ($(this).hasClass('active')) {
//       $('[data-submenu]').removeClass('active');
//       $('[data-dropdown]').removeClass('active');
//     }
//   },
//   timeout: 350
// })

$('.nav-dropdown').on('mouseleave', function () {
  if ($(this).hasClass('active')) {
    $('[data-submenu]').removeClass('active');
    $('[data-sub-submenu]').removeClass('active');
    $('[data-dropdown]').removeClass('active');
  }
});

// initMap();

// $('.mobile-nav--item a').on('click', function (e) {
//   e.preventDefault();
// })
//
$('ol.order-list').wrap('<div class="order-list-container"></div>');

$('.icon--search').on('click', function () {
  $(this).closest('.search-toggle-container').toggleClass('active');
  $(this).closest('.mobile-search-toggle-container').toggleClass('active');
  $(this).closest('.search-toggle-container').find('input').focus();
  $(this).closest('.mobile-search-toggle-container').find('input').focus();
});

$('body').on('click', '[data-gallery]', function (e) {
  e.stopPropagation();
  e.preventDefault();
  var $this = $(this);
  var id = $this.data('gallery');
  var index = $this.data('index');

  var pswpElement = $('.pswp')[0];
  var options = {
    galleryUID: id,
    bgOpacity: 0.85,
    index: index,
    shareEl: false,
    history: false
  };
  var referenceItems = window[id];
  var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, referenceItems, options);
  gallery.init();
});

$('.gform_drop_instructions').each(function () {
  $(this).text('Dateien hier ablegen oder');
  $(this).innerHTML = 'Dateien hier ablegen oder';
});

$('.gform_button_select_files').each(function () {
  $(this).text('Auswählen');
});

$('.fixed-maintenance-notice').on('click', function () {
  // save to localStorage for 1 hour
  localStorage.setItem('hideMaintenanceNotice', date.getTime() + 5 * 60 * 1000);
  $(this).hide(1000);
});

let hideNotice = localStorage.getItem('hideMaintenanceNotice');
let date = new Date();
if (hideNotice && parseInt(hideNotice, 10) > date.getTime()) {
  $('.fixed-maintenance-notice').hide();
}
showPopup();

function showPopup() {
  let $popup = $('.popup-wrap');
  let date = new Date();
  if ($popup.length > 0) {
    let $popupClose = $('.popup--close button');
    $popupClose.on('click', function () {
      if (window.localStorage) {
        localStorage.setItem('popup_shown', date.getTime() + 24 * 60 * 1000);
      }
      $popup.removeClass('active');
    });
    let admin = $popup.data('admin');
    if (admin === true) {
      setTimeout(function () {
        $popup.addClass('active');
      }, 2000);
      return;
    }
    let popupShown = localStorage.getItem('popup_shown');
    if (!popupShown) popupShown = date.getTime() - 1;
    if (popupShown && parseInt(popupShown, 10) < date.getTime()) {
      setTimeout(function () {
        $popup.addClass('active');
      }, 2000);
    }
  }
}
